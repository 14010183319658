<template>
	<div class=" text-black relative">
		<!-- --------- Priorities makes the different. Be the different. ----------- -->
		<div class="w-full flex flex-col items-center justify-between z-10">
			<div
				class="w-full h-20 md:h-36 bg-white relative border-black flex flex-col justify-center"
			>
				<img
					class="h-auto xl:h-36 ml-auto z-10 absolute top-0 right-0"
					src="@/assets/images/gif/EmoQi-Cover-FeMale.gif"
				/>
				<PQContainer class="z-20">
					<div
						class="text-3xl md:text-5xl font-mont font-medium text-black-500 py-12"
					>
						<a href="https://apptodesign.com">
							<img
								class="h-12 w-auto md:h-20 md:w-auto"
								src="@/assets/images/a2d-logo-retro/a2d-retro-logo-text-dark.svg"
							/>
						</a>
					</div>
				</PQContainer>
			</div>
			<PQContainer class="w-full h-max ">
				<div
					class="w-full grid grid-cols-1 md:grid-cols-2 lg:gap-12 items-center"
				>
					<div class="w-full py-12">
						<!-- <img
							class="w-full h-auto md:w-full xl:w-2/3 lg:h-auto mx-auto"
							src="@/assets/images/svg/Be.svg"
						/> -->
						<img
							class="w-full h-auto md:w-full xl:w-2/3 lg:h-auto mx-auto"
							src="@/assets/images/gif/Be_You.gif"
						/>
						<div
							class="bg-red-500 bg-opacity-25 w-full mt-6"
							style="height: 1px;"
						></div>
						<img
							class="w-40 h-auto mx-auto"
							src="@/assets/images/logo/EmoQi-Logo.svg"
						/>
						<div
							class="text-2xl font-rad-i text-red-400 mx-auto w-max -mt-2"
						>
							#beyou&nbsp;#emoqi
						</div>
					</div>
					<div
						class="w-full h-full p-6 md:p-12 flex flex-col justify-around items-start"
					>
						<div>
							<div
								class="text-xl xl:text-3xl font-semibold text-black text-opacity-30"
							>
								Customised Packs of beautiful
								<span
									class="text-red-500 text-3xl xl:text-5xl font-rad-i"
									style="line-height: 0.6"
								>
									emojified
								</span>
								illustrations, perfectly fitted for
								<span class="text-black font-bold"
									>Cover Photos</span
								>
								in
								<span class="text-black font-bold">Notion</span>
								and
								<span class="text-black font-bold"
									>Social Networks</span
								>
								like Twitter, Facebook, Tumblr, Reddit, Discord
								...
							</div>
							<div
								class="pr-2 text-4xl md:text-5xl lg:text-6xl py-6 font-bang tracking-wider bg-clip-text text-transparent bg-gradient-to-r from-red-600 via-purple-600 to-indigo-600"
								style="word-spacing: 8px;"
							>
								For the Trendsetter!
							</div>
							<div
								class="text-xl xl:text-3xl font-semibold text-black text-opacity-30"
							>
								<!-- Flaunt your
								<span
									class="text-red-500 text-2xl xl:text-4xl font-extrabold font-rad-i"
								>
									true self </span
								>.  -->
								Be it your professional or personal workspace!!
							</div>
						</div>
						<div>
							<div
								:class="
									'text-3xl lg:text-6xl py-6 font-medium pr-2 font-bang ' +
										colors[curr_clr]
								"
								style="word-spacing: 4px"
							>
								Try for Free !!
							</div>
							<a
								class="gumroad-button"
								href="https://gum.co/emoqi"
								target="_blank"
							>
								Download from Gumroad
							</a>
						</div>
					</div>
				</div>
				<Footer />
			</PQContainer>
		</div>
		<div
			class="w-full h-screen fixed top-0 left-0 right-0 bg-black bg-opacity-100 z-20 bg-blur flex flex-row items-center justify-center"
			v-if="show"
		>
			<div
				class="absolute top-0 right-0 w-full h-screen flex flex-row justify-center items-center"
			>
				<img
					class="h-64 w-64 rounded-2xl "
					src="@/assets/images/gif/EmoQi-Logo-FeMale2.gif"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PQContainer from "../layouts/PQContainer";
import Footer from "../components/Footer2";

export default {
	components: { PQContainer, Footer },
	data() {
		return {
			show: true,
			colors: [
				"text-black",
				"text-red-400",
				"text-blue-400",
				"text-green-400",
				"text-purple-4500",
				"text-yellow-400"
			],
			curr_clr: 0
		};
	},
	mounted() {
		var app = this;
		setTimeout(() => {
			app.show = false;
		}, 2000);
		// setInterval(() => {
		// 	if (app.curr_clr == app.colors.length - 1) {
		// 		app.curr_clr = 0;
		// 	} else {
		// 		app.curr_clr += 1;
		// 	}
		// }, 300);
	}
};
</script>
