<template>
	<div class="w-full footer-background">
		<div class="container mx-auto text-left p-12 ">
			<div
				class="grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:gap-4 xl:gap-8 font-normal py-12"
			>
				<div
					class="md:col-span-2 lg:col-span-2 font-medium pb-2 text-blue-600"
				>
					<a href="/"
						><img
							class="w-24 rounded-xl"
							src="@/assets/images/a2d-logo-retro/a2d-retro-logo-frame.png"
					/></a>
				</div>
				<div
					class="pt-6 md:pt-0"
					v-for="(footer, index) in footer_items"
					:key="'footer_' + index"
				>
					<div class="text-xl font-bold pb-2 text-blue-600">
						{{ footer.header }}
					</div>
					<a
						:href="item.link"
						v-for="(item, i) in footer.items"
						:key="'footer_link_' + i"
					>
						<div class="text-sm font-bold text-black py-1">
							{{ item.title }}
						</div>
					</a>
				</div>
			</div>
			<div
				class="bg-black bg-opacity-25 my-6 rounded-full"
				style="height: 1px;"
			></div>
			<div class="text-sm text-center text-black text-opacity-25 py-2">
				&copy; AppToDesign 2021 - All Rights Reserved
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			footer_items: [
				{
					header: "Social",
					items: [
						// {
						// 	title: "Reddit",
						// 	link: "https://www.reddit.com/user/apptodesign"
						// },
						{
							title: "Instagram",
							link: "https://www.instagram.com/apptodesign"
						},
						{
							title: "Facebook",
							link: "https://www.facebook.com/apptodesignteam"
						},
						{
							title: "Twitter",
							link: "https://twitter.com/apptodesignteam"
						}
					]
				},
				{
					header: "Help",
					items: [
						{
							title: "Mail Us",
							link: "/support"
						},
						{
							title: "Discord",
							link: "https://discord.gg/PkXYGwTgrM"
						}
					]
				}
			]
		};
	}
};
</script>
