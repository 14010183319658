<template>
	<div class="w-full md:fixed z-50">
		<PQContainer>
			<div
				class="py-6 md:py-4 xl:py-6 flex flex-row justify-center items-center"
			>
				<div class="text-6xl font-mont">
					<a href="https://emoqi.apptodesign.com">
						EmoQi
					</a>
				</div>
			</div>
		</PQContainer>
	</div>
</template>

<script>
import PQContainer from "../layouts/PQContainer";

export default {
	components: { PQContainer }
};
</script>
