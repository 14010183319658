<template>
	<div :class="bg + ' w-full '">
		<div class="container mx-auto px-6 md:px-12 ">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	props: ["bg"]
};
</script>
