<template>
	<div>
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div
				class="mx-auto flex flex-col h-screen items-center justify-between"
			>
				<div class="pt-48 text-6xl font-q-bold text-black">
					Your Lost!
				</div>
				<div class="w-full pb-12">
					<Footer></Footer>
				</div>
			</div>
		</PQContainer>
	</div>
</template>

<script>
import PQContainer from "../layouts/PQContainer";
import Footer from "../components/Footer";

export default {
	components: { PQContainer, Footer },
	data() {
		return {};
	}
};
</script>

<style scoped>
.support-form {
	width: 600px;
	height: 500px;
}

@media only screen and (max-width: 600px) {
	.support-form {
		width: 330px;
		height: 500px;
	}
}
</style>
