<template>
	<div class="bg-black mb-6" style="height:1px;"></div>
	<div
		class="flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between items-center font-semibold text-black mb-6"
	>
		<div class="">
			Made with
			<span class="text-black">❤️</span>
			by
			<a
				target="_blank"
				class="cursor-pointer underline hover:text-blue-600"
				href="https://apptodesign.com"
				>AppToDesign</a
			>
			in India
		</div>
		<div class="flex flex-row gap-4 underline">
			<a
				class="color-instagram"
				target="_blank"
				href="https://www.instagram.com/apptodesign"
				>Instagram</a
			>
			<a
				class="color-facebook"
				target="_blank"
				href="https://www.facebook.com/apptodesignteam"
				>Facebook</a
			>
			<a
				class="color-twitter"
				target="_blank"
				href="https://twitter.com/apptodesignteam"
				>Twitter</a
			>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	}
};
</script>
