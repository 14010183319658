import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/Landing.vue";
import Terms from "../views/Terms.vue";
import Page404 from "../views/404.vue";

const routes = [
	{
		path: "/",
		name: "Landing",
		component: Landing
	},
	{
		path: "/terms",
		name: "Terms",
		component: Terms
	},
	{
		path: "/:pathMatch(.*)*",
		name: "Page404",
		component: Page404,
		props: true
	}
];

const router = createRouter({
	mode: "history",
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
