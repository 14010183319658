<template>
	<div id="app" class="w-full  text-gray-400">
		<!-- <Navbar></Navbar> -->
		<router-view></router-view>
		<!-- <div
			class="fixed z-50 right-1 bottom-1 text-sm px-2 py-0 rounded-md text-black bg-green-400 font-work tracking-widest"
		>
			<div
				class="block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden"
			>
				sm
			</div>
			<div
				class="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden"
			>
				sm
			</div>
			<div
				class="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden"
			>
				md
			</div>
			<div
				class="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden"
			>
				lg
			</div>
			<div
				class="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden"
			>
				xl
			</div>
			<div
				class="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block"
			>
				xl
			</div>
		</div> -->
	</div>
</template>

<script>
import Navbar from "./components/Navbar";
export default {
	components: { Navbar }
};
</script>
<style>
#app {
	font-family: "Montserrat", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* color: #2c3e50; */
}
</style>
